exports.components = {
  "component---src-pages-apps-audio-transcription-js": () => import("./../../../src/pages/apps/audio-transcription.js" /* webpackChunkName: "component---src-pages-apps-audio-transcription-js" */),
  "component---src-pages-apps-directory-js": () => import("./../../../src/pages/apps/directory.js" /* webpackChunkName: "component---src-pages-apps-directory-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kb-js": () => import("./../../../src/pages/kb.js" /* webpackChunkName: "component---src-pages-kb-js" */),
  "component---src-pages-on-demand-js": () => import("./../../../src/pages/on-demand.js" /* webpackChunkName: "component---src-pages-on-demand-js" */),
  "component---src-templates-blog-template-jsx-content-file-path-content-kb-ai-and-human-transcription-synergy-index-mdx": () => import("./../../../src/templates/blog-template.jsx?__contentFilePath=/Volumes/MyWork/code-dryer-projects/transkripthor-web/content/kb/ai-and-human-transcription-synergy/index.mdx" /* webpackChunkName: "component---src-templates-blog-template-jsx-content-file-path-content-kb-ai-and-human-transcription-synergy-index-mdx" */),
  "component---src-templates-blog-template-jsx-content-file-path-content-kb-ai-transcription-evolution-index-mdx": () => import("./../../../src/templates/blog-template.jsx?__contentFilePath=/Volumes/MyWork/code-dryer-projects/transkripthor-web/content/kb/ai-transcription-evolution/index.mdx" /* webpackChunkName: "component---src-templates-blog-template-jsx-content-file-path-content-kb-ai-transcription-evolution-index-mdx" */),
  "component---src-templates-blog-template-jsx-content-file-path-content-kb-ai-wont-replace-legal-jobs-index-mdx": () => import("./../../../src/templates/blog-template.jsx?__contentFilePath=/Volumes/MyWork/code-dryer-projects/transkripthor-web/content/kb/ai-wont-replace-legal-jobs/index.mdx" /* webpackChunkName: "component---src-templates-blog-template-jsx-content-file-path-content-kb-ai-wont-replace-legal-jobs-index-mdx" */),
  "component---src-templates-blog-template-jsx-content-file-path-content-kb-arbitration-venues-in-malaysia-index-mdx": () => import("./../../../src/templates/blog-template.jsx?__contentFilePath=/Volumes/MyWork/code-dryer-projects/transkripthor-web/content/kb/arbitration-venues-in-malaysia/index.mdx" /* webpackChunkName: "component---src-templates-blog-template-jsx-content-file-path-content-kb-arbitration-venues-in-malaysia-index-mdx" */),
  "component---src-templates-blog-template-jsx-content-file-path-content-kb-audio-quality-challenges-transcription-index-mdx": () => import("./../../../src/templates/blog-template.jsx?__contentFilePath=/Volumes/MyWork/code-dryer-projects/transkripthor-web/content/kb/audio-quality-challenges-transcription/index.mdx" /* webpackChunkName: "component---src-templates-blog-template-jsx-content-file-path-content-kb-audio-quality-challenges-transcription-index-mdx" */),
  "component---src-templates-blog-template-jsx-content-file-path-content-kb-cost-benefit-outsourcing-transcription-index-mdx": () => import("./../../../src/templates/blog-template.jsx?__contentFilePath=/Volumes/MyWork/code-dryer-projects/transkripthor-web/content/kb/cost-benefit-outsourcing-transcription/index.mdx" /* webpackChunkName: "component---src-templates-blog-template-jsx-content-file-path-content-kb-cost-benefit-outsourcing-transcription-index-mdx" */),
  "component---src-templates-blog-template-jsx-content-file-path-content-kb-cost-vs-features-index-mdx": () => import("./../../../src/templates/blog-template.jsx?__contentFilePath=/Volumes/MyWork/code-dryer-projects/transkripthor-web/content/kb/cost-vs-features/index.mdx" /* webpackChunkName: "component---src-templates-blog-template-jsx-content-file-path-content-kb-cost-vs-features-index-mdx" */),
  "component---src-templates-blog-template-jsx-content-file-path-content-kb-email-templates-for-lawyers-malaysia-index-mdx": () => import("./../../../src/templates/blog-template.jsx?__contentFilePath=/Volumes/MyWork/code-dryer-projects/transkripthor-web/content/kb/email-templates-for-lawyers-malaysia/index.mdx" /* webpackChunkName: "component---src-templates-blog-template-jsx-content-file-path-content-kb-email-templates-for-lawyers-malaysia-index-mdx" */),
  "component---src-templates-blog-template-jsx-content-file-path-content-kb-helpful-chatgpt-prompts-for-lawyers-index-mdx": () => import("./../../../src/templates/blog-template.jsx?__contentFilePath=/Volumes/MyWork/code-dryer-projects/transkripthor-web/content/kb/helpful-chatgpt-prompts-for-lawyers/index.mdx" /* webpackChunkName: "component---src-templates-blog-template-jsx-content-file-path-content-kb-helpful-chatgpt-prompts-for-lawyers-index-mdx" */),
  "component---src-templates-blog-template-jsx-content-file-path-content-kb-live-transcription-service-features-index-mdx": () => import("./../../../src/templates/blog-template.jsx?__contentFilePath=/Volumes/MyWork/code-dryer-projects/transkripthor-web/content/kb/live-transcription-service-features/index.mdx" /* webpackChunkName: "component---src-templates-blog-template-jsx-content-file-path-content-kb-live-transcription-service-features-index-mdx" */),
  "component---src-templates-blog-template-jsx-content-file-path-content-kb-live-vs-recorded-transcription-index-mdx": () => import("./../../../src/templates/blog-template.jsx?__contentFilePath=/Volumes/MyWork/code-dryer-projects/transkripthor-web/content/kb/live-vs-recorded-transcription/index.mdx" /* webpackChunkName: "component---src-templates-blog-template-jsx-content-file-path-content-kb-live-vs-recorded-transcription-index-mdx" */),
  "component---src-templates-blog-template-jsx-content-file-path-content-kb-malaysia-law-rule-24-index-mdx": () => import("./../../../src/templates/blog-template.jsx?__contentFilePath=/Volumes/MyWork/code-dryer-projects/transkripthor-web/content/kb/malaysia-law-rule-24/index.mdx" /* webpackChunkName: "component---src-templates-blog-template-jsx-content-file-path-content-kb-malaysia-law-rule-24-index-mdx" */),
  "component---src-templates-blog-template-jsx-content-file-path-content-kb-maximize-transcribed-documents-index-mdx": () => import("./../../../src/templates/blog-template.jsx?__contentFilePath=/Volumes/MyWork/code-dryer-projects/transkripthor-web/content/kb/maximize-transcribed-documents/index.mdx" /* webpackChunkName: "component---src-templates-blog-template-jsx-content-file-path-content-kb-maximize-transcribed-documents-index-mdx" */),
  "component---src-templates-blog-template-jsx-content-file-path-content-kb-privacy-security-transcription-index-mdx": () => import("./../../../src/templates/blog-template.jsx?__contentFilePath=/Volumes/MyWork/code-dryer-projects/transkripthor-web/content/kb/privacy-security-transcription/index.mdx" /* webpackChunkName: "component---src-templates-blog-template-jsx-content-file-path-content-kb-privacy-security-transcription-index-mdx" */),
  "component---src-templates-blog-template-jsx-content-file-path-content-kb-secure-transcription-services-index-mdx": () => import("./../../../src/templates/blog-template.jsx?__contentFilePath=/Volumes/MyWork/code-dryer-projects/transkripthor-web/content/kb/secure-transcription-services/index.mdx" /* webpackChunkName: "component---src-templates-blog-template-jsx-content-file-path-content-kb-secure-transcription-services-index-mdx" */),
  "component---src-templates-blog-template-jsx-content-file-path-content-kb-selecting-the-best-arbitration-services-in-malaysia-index-mdx": () => import("./../../../src/templates/blog-template.jsx?__contentFilePath=/Volumes/MyWork/code-dryer-projects/transkripthor-web/content/kb/selecting-the-best-arbitration-services-in-malaysia/index.mdx" /* webpackChunkName: "component---src-templates-blog-template-jsx-content-file-path-content-kb-selecting-the-best-arbitration-services-in-malaysia-index-mdx" */),
  "component---src-templates-blog-template-jsx-content-file-path-content-kb-tech-books-for-modern-law-firms-index-mdx": () => import("./../../../src/templates/blog-template.jsx?__contentFilePath=/Volumes/MyWork/code-dryer-projects/transkripthor-web/content/kb/tech-books-for-modern-law-firms/index.mdx" /* webpackChunkName: "component---src-templates-blog-template-jsx-content-file-path-content-kb-tech-books-for-modern-law-firms-index-mdx" */),
  "component---src-templates-blog-template-jsx-content-file-path-content-kb-top-5-free-productivity-tools-for-lawyers-index-mdx": () => import("./../../../src/templates/blog-template.jsx?__contentFilePath=/Volumes/MyWork/code-dryer-projects/transkripthor-web/content/kb/top-5-free-productivity-tools-for-lawyers/index.mdx" /* webpackChunkName: "component---src-templates-blog-template-jsx-content-file-path-content-kb-top-5-free-productivity-tools-for-lawyers-index-mdx" */),
  "component---src-templates-blog-template-jsx-content-file-path-content-kb-transcribing-old-audio-files-index-mdx": () => import("./../../../src/templates/blog-template.jsx?__contentFilePath=/Volumes/MyWork/code-dryer-projects/transkripthor-web/content/kb/transcribing-old-audio-files/index.mdx" /* webpackChunkName: "component---src-templates-blog-template-jsx-content-file-path-content-kb-transcribing-old-audio-files-index-mdx" */),
  "component---src-templates-blog-template-jsx-content-file-path-content-kb-transcription-transforms-legal-practice-index-mdx": () => import("./../../../src/templates/blog-template.jsx?__contentFilePath=/Volumes/MyWork/code-dryer-projects/transkripthor-web/content/kb/transcription-transforms-legal-practice/index.mdx" /* webpackChunkName: "component---src-templates-blog-template-jsx-content-file-path-content-kb-transcription-transforms-legal-practice-index-mdx" */),
  "component---src-templates-blog-template-jsx-content-file-path-content-kb-type-of-legal-transcription-services-index-mdx": () => import("./../../../src/templates/blog-template.jsx?__contentFilePath=/Volumes/MyWork/code-dryer-projects/transkripthor-web/content/kb/type-of-legal-transcription-services/index.mdx" /* webpackChunkName: "component---src-templates-blog-template-jsx-content-file-path-content-kb-type-of-legal-transcription-services-index-mdx" */),
  "component---src-templates-blog-template-jsx-content-file-path-content-kb-ultimate-useful-links-for-lawyers-in-malaysia-index-mdx": () => import("./../../../src/templates/blog-template.jsx?__contentFilePath=/Volumes/MyWork/code-dryer-projects/transkripthor-web/content/kb/ultimate-useful-links-for-lawyers-in-malaysia/index.mdx" /* webpackChunkName: "component---src-templates-blog-template-jsx-content-file-path-content-kb-ultimate-useful-links-for-lawyers-in-malaysia-index-mdx" */),
  "component---src-templates-blog-template-jsx-content-file-path-content-kb-vendor-support-for-legal-transcription-index-mdx": () => import("./../../../src/templates/blog-template.jsx?__contentFilePath=/Volumes/MyWork/code-dryer-projects/transkripthor-web/content/kb/vendor-support-for-legal-transcription/index.mdx" /* webpackChunkName: "component---src-templates-blog-template-jsx-content-file-path-content-kb-vendor-support-for-legal-transcription-index-mdx" */)
}

